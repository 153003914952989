import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, iif, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, concat, delay, filter, map, mergeMap, shareReplay, skip, switchMap, tap } from 'rxjs/operators';

import { DateTime } from 'luxon';

import { MessageDto, MessagePage, SocketSendMessageData } from '@dto';
import { ChannelType, ChatEvents } from '@enums';
import { SocketNamespaces } from '@models';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

import { UtilityService } from './utility.service';
import { AlertService } from './alert.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SocketService } from './socket.service';
import { Socket } from 'socket.io-client';
import { ProviderNamePipe } from '../../shared/pipes/provider-name.pipe';
import { DateUtils } from '../date.utils';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public chatSockets: Observable<ReturnType<ChatService['connectToChatSocketsForPatient']> | null>;
  public chatChannelCopiedFrom = new BehaviorSubject<ChannelType>(null);

  // Provider Messages
  public providerMessageData: Observable<MessagePage>;
  public providerLoading: Observable<boolean>;

  public coordinatorMessageData: Observable<MessagePage>;
  public coordinatorLoading: Observable<boolean>;

  public unreadMessages: Observable<MessageDto[]>;
  public recentMessages: Observable<MessageDto[]>;

  // Tracks whether the "use the copy feature" message has been shown to limit to once per session
  public copyMessageShown = false;

  public unreadMessageCount = new ReplaySubject<number>(1);

  public totalMessageCount = combineLatest([this.unreadMessageCount]).pipe(
    map((counts) => counts.reduce((sum, count) => sum + count)),
    shareReplay(1)
  );

  private _chatSockets = new BehaviorSubject<ReturnType<ChatService['connectToChatSocketsForPatient']> | null>(null);

  private _currentPatientId = new BehaviorSubject<number>(null);
  private loadMessages$ = new BehaviorSubject(null);

  // Provider Messages
  private providerPage = new BehaviorSubject(0); // Triggers to get new page of messages
  private providerOffset = new BehaviorSubject(0); // By how much is the page retrieval offset to account for new messages
  private _providerMessageData = new BehaviorSubject<MessagePage>({
    count: 0,
    data: {},
    dataArray: [],
  });
  private _providerLoading = new BehaviorSubject(false); // Whether loading new provider pages

  // Coordinator Messages
  private coordinatorPage = new BehaviorSubject(0); // Triggers to get new page of messages
  private coordinatorOffset = new BehaviorSubject(0); // By how much is the page retrieval offset to account for new messages
  private _coordinatorMessageData = new BehaviorSubject<MessagePage>({
    count: 0,
    data: {},
    dataArray: [],
  });
  private _coordinatorLoading = new BehaviorSubject(false); // Whether loading new coordinator pages

  private _unreadMessages = new ReplaySubject<MessageDto[]>(1);
  private _recentMessages = new BehaviorSubject<MessageDto[]>([]);

  private _unreadMessageCount: Observable<number>;

  private canDoOptimisticUpdate = true;
  private lastMessageReceivedAt = DateUtils.cleanTimestampString(DateTime.local().toISO());
  private pollingRate: number;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private http: HttpClient,
    private utilityService: UtilityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private socketService: SocketService,
    private providerNamePipe: ProviderNamePipe
  ) {
    this.chatSockets = this._chatSockets.asObservable();
    this.providerMessageData = this._providerMessageData.asObservable();
    this.providerLoading = this._providerLoading.asObservable();
    this.coordinatorMessageData = this._coordinatorMessageData.asObservable();
    this.coordinatorLoading = this._coordinatorLoading.asObservable();
    this.unreadMessages = this._unreadMessages.asObservable();
    this.recentMessages = this._recentMessages.asObservable();

    this._unreadMessageCount = this.unreadMessages.pipe(map((messages) => messages.length));

    this.initMessagePageLoading(
      this.providerPage,
      this._providerLoading,
      this.providerOffset,
      this._providerMessageData,
      ChannelType.provider
    );

    this.initMessagePageLoading(
      this.coordinatorPage,
      this._coordinatorLoading,
      this.coordinatorOffset,
      this._coordinatorMessageData,
      ChannelType.coordinator
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.pollingRate = event.shortenChatPollingRate
          ? environment.chatPollingRate
          : environment.chatPollingRateBackground;
      });

    this._currentPatientId.pipe(filter((patientId) => !!patientId)).subscribe((patientId) => {
      const sockets = this._chatSockets.value;
      if (sockets) {
        Object.values(sockets).forEach((socket) => socket.disconnect());
      }

      this._chatSockets.next(this.connectToChatSocketsForPatient(patientId));
    });

    const whenToRefresh$ = of([]).pipe(
      switchMap(() => of([]).pipe(delay(this.pollingRate))),
      tap((_) => this.loadMessages$.next([])),
      skip(1)
    );

    this.loadMessages$
      .pipe(
        switchMap(() => this._currentPatientId),
        switchMap((patientId) => this.getMessagesSinceUpdate().pipe(concat(whenToRefresh$)))
      )
      .subscribe((messages) => this.handleNewMessageData(messages));

    this.utilityService
      .createNewPollingObservable<MessageDto[]>(this.fetchUnreadMessages.bind(this), environment.chatPollingRate)
      .subscribe((messages) => this._unreadMessages.next(messages));

    this.utilityService
      .createNewPollingObservable<MessageDto[]>(this.fetchRecentMessages.bind(this), environment.chatPollingRate)
      .subscribe((messages) => this._recentMessages.next(messages));

    this._unreadMessageCount.subscribe((count) => this.unreadMessageCount.next(count));
  }

  public getSenderName(userId: string): string {
    const senderNames = this.providerNamePipe.transform(userId, 'withCredentials');
    let senderName: string = '';
    if (Array.isArray(senderNames) && senderNames.length === 1) {
      senderName = `${senderNames[0]}`;
    }

    return senderName;
  }

  // Trigger a page to advance, making it request more messages
  public triggerNextMessagePage(channel: ChannelType) {
    switch (channel) {
      case ChannelType.provider:
        this.providerPage.next(this.providerPage.value + 1);
        break;
      case ChannelType.coordinator:
        this.coordinatorPage.next(this.coordinatorPage.value + 1);
        break;
    }
  }

  public sortMessageData(data: { [messageId: number]: MessageDto }) {
    return Object.values(data).sort(
      (msgA, msgB) => new Date(msgB.createdOn).valueOf() - new Date(msgA.createdOn).valueOf()
    );
  }

  /**
   * Attempts to retrieve all messages updated or sent since the last time it checked
   * @returns Array of messages it found
   */
  public getMessagesSinceUpdate(): Observable<MessageDto[]> {
    if (!!this._currentPatientId.value && this.lastMessageReceivedAt) {
      const params = new HttpParams({
        fromObject: {
          datetime: this.lastMessageReceivedAt,
        },
      });
      const url = environment.apiV3Url.concat(`/messages/updated/${this._currentPatientId.value}`);

      return this.http.get<MessageDto[]>(url, { params }).pipe(
        catchError((error) => {
          console.error('Failed to poll for updated messages.', error);
          return of([]);
        })
      );
    }
    // If not currentPatientId is stored, return empty collection
    return of([]);
  }

  public getMessagePage(patientId: number, channel: ChannelType, page: number, offset: number) {
    const url = environment.apiV3Url.concat(`/messages/message-page/${patientId}`);

    const params = new HttpParams({
      fromObject: {
        page: page.toString(),
        offset: offset.toString(),
        channel: channel.toString(),
      },
    });
    return this.http.get<MessagePage>(url, { params }).pipe(
      map((messagePage) => ({
        ...messagePage,
        page,
      }))
    );
  }

  public connectToChatSocketsForPatient(patientId: string | number) {
    const [provider, careCoordinator] = [SocketNamespaces.providerChat, SocketNamespaces.careCoordinatorChat].map(
      (namespace) => {
        const socket = this.socketService.getSocket(namespace.getClientPath(patientId));
        let retries = 0;
        socket.on('connect_error', (error) => {
          console.error(error);
          console.error(JSON.stringify(error));
          // SocketIO should retry itself, this is a work around while we investigate
          // intermittent socket connection failures
          if (retries < 3 && socket.disconnected) {
            console.error(new Error('3 socket reconnection attempts failed'));
            retries++;
            socket.connect();
          }
        });
        socket.on('error', (error) => {
          console.error(error);
          console.error(JSON.stringify(error));
        });
        socket.on(ChatEvents.message, (message: MessageDto) => {
          this.handleNewMessageData([message]);
        });
        socket.connect();
        return socket;
      }
    );

    return {
      provider,
      careCoordinator,
    };
  }

  public updatePatientId(id: number) {
    // Can return immediately if patientId is the same
    if (this._currentPatientId.getValue() === id) {
      return;
    }
    this._providerMessageData.next({
      data: {},
      dataArray: [],
      count: 0,
    });

    this._coordinatorMessageData.next({
      data: {},
      dataArray: [],
      count: 0,
    });
    this.lastMessageReceivedAt = DateUtils.cleanTimestampString(DateTime.local().toISO());
    this._currentPatientId.next(id);

    this.providerOffset.next(0);
    this.providerPage.next(0);

    this.coordinatorOffset.next(0);
    this.coordinatorPage.next(0);
  }

  public async sendMessageUsingSocket(socket: Socket, message: SocketSendMessageData) {
    socket.emit(ChatEvents.message, message, (response) => {
      if ('error' in message) {
        console.error('Error sending message');
        console.error(socket);
        throw new Error('Message failed to send');
      }
      return this.handleNewMessageData([response]);
    });
  }

  public async sendMessage(
    text: string,
    patientId: number,
    channel: ChannelType,
    updateMessageStore = true,
    options: Partial<MessageDto> = {}
  ) {
    try {
      const url = environment.apiUrl.concat('/messages');

      const userId = this.authService.currentUserId;
      const senderName: string = this.getSenderName(userId);
      const data: MessageDto = {
        ...options,
        id: null,
        createdOn: null,
        updatedOn: null,
        deleted: false,
        text,
        channel,
        userId,
        patientId,
        senderName,
        fromNiceStaff: true,
        claimedByNiceStaff: true,
        readByPatient: false,
        readUserIds: [userId],
      };

      const result = await this.http.post<MessageDto>(url, data).toPromise();

      // Update the message store containing messages with the current active patient with the response
      if (updateMessageStore) {
        this.handleNewMessageData([result], true);
      }
      return result;
    } catch (error) {
      if (updateMessageStore) {
        // A regular chat message, just alert user
        this.alertService.error('Error sending message, please retry.');
      } else {
        // Called as part of task creation, pass error up
        throw error;
      }
    }
  }

  public async sendMessageForCompany(text: string, companyId: number) {
    const url = environment.apiUrl.concat('/messages/company');
    const userId = this.authService.currentUserId;
    const senderName: string = this.getSenderName(userId);
    const data = {
      text,
      companyId,
      userId,
      senderName,
    };

    const result: any = await this.http.post(url, data).toPromise();
    if (result.error) {
      this.alertService.error(result.error);
    } else {
      return result;
    }
  }

  public async markMessagesAsRead(messageId: number) {
    const url = environment.apiUrl.concat(`/messages/claim/${messageId}`);
    const result = await this.http.put(url, {}).toPromise();

    return result;
  }

  /**
   * Marks the target message as read or unread for the current user.
   *
   * @param messageId The database id of the message to update.
   * @param status true marks the message as read, false marks it as unread.
   *
   * @return the {@link Observable} that to execute the update.
   */
  public updateMessageReadUserIds(messageId: number, status: boolean): Observable<any> {
    const url = environment.apiV3Url.concat(`/messages/read-status/${messageId}`);

    return this.http.put(url, { status });
  }

  public async markChannelAsRead(channel: ChannelType) {
    switch (channel) {
      case ChannelType.provider:
        return this.markMessagesAsRead(this._providerMessageData.value.dataArray[0].id);
      case ChannelType.coordinator:
        return this.markMessagesAsRead(this._coordinatorMessageData.value.dataArray[0].id);
    }
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private fetchUnreadMessages(): Observable<MessageDto[]> {
    if (!this.authService.isAuthenticated()) {
      return of([]);
    }

    const url = environment.apiUrl.concat('/messages/unread');
    return this.http.get<MessageDto[]>(url).pipe(
      catchError((error) => {
        console.error('Failed to poll for unread messages.', error);
        return of([]);
      })
    );
  }

  private fetchRecentMessages(): Observable<MessageDto[]> {
    if (!this.authService.isAuthenticated()) {
      return of([]);
    }

    const url = environment.apiUrl.concat('/messages/recent');
    return this.http.get<MessageDto[]>(url).pipe(
      catchError((error) => {
        console.error('Failed to poll for recent messages.', error);
        return of([]);
      })
    );
  }

  private handleNewMessageData(messages: MessageDto[], skipMostRecent = false) {
    if (!messages || messages.length === 0) {
      return;
    }

    const providerMessages = messages.filter((message) => message.channel === ChannelType.provider);
    const coordinatorMessages = messages.filter((message) => message.channel === ChannelType.coordinator);

    if (providerMessages.length > 0) {
      this.processMessageData(this._providerMessageData, providerMessages);
    }

    if (coordinatorMessages.length > 0) {
      this.processMessageData(this._coordinatorMessageData, coordinatorMessages);
    }

    const mostRecentMessage = this.sortMessageData(messages)[0];

    if (mostRecentMessage && !skipMostRecent) {
      this.updateLastMessageReceivedAt(mostRecentMessage.updatedOn as string);
    }
  }

  private processMessageData(dataSubject: BehaviorSubject<MessagePage>, messages: MessageDto[]) {
    const store = dataSubject.getValue();
    let count = store.count;
    const data = store.data;
    const dataArray = this.sortMessageData(data);

    messages.forEach((message) => {
      // Cancel out of updating if the patient Ids are not in sync
      if (message.patientId !== this._currentPatientId.value) {
        return;
      }

      if (store.data[message.id] === undefined || store.data[message.id].updatedOn < message.updatedOn) {
        store.data[message.id] = message;
      }

      // Increment offset if message is not contained in store
      const increment = this.incrementPageOffset(message);
      count += increment;
    });

    dataSubject.next({
      data,
      count,
      dataArray,
    });
  }

  private incrementPageOffset(message: MessageDto): number {
    // Determine if message is new
    let foundMessage: MessageDto;
    switch (message.channel) {
      case ChannelType.provider:
        foundMessage = this._providerMessageData.getValue().data[message.id];
        if (!foundMessage) {
          this.providerOffset.next(this.providerOffset.value + 1);
          return 1;
        }
        break;
      case ChannelType.coordinator:
        foundMessage = this._coordinatorMessageData.getValue().data[message.id];
        if (!foundMessage) {
          this.coordinatorOffset.next(this.coordinatorOffset.value + 1);
          return 1;
        }
        break;
    }
    return 0;
  }

  private initMessagePageLoading(
    pageSubject: BehaviorSubject<number>,
    loadingSubject: BehaviorSubject<boolean>,
    offsetSubject: BehaviorSubject<number>,
    messageDataSubject: BehaviorSubject<MessagePage>,
    channel: ChannelType
  ) {
    pageSubject
      .asObservable()
      .pipe(
        // Prevent firing if currentPatientId is not legit
        switchMap((page) =>
          // If patientId is invalid, do not pass go
          iif(() => !!this._currentPatientId.getValue(), of([page, this._currentPatientId.value]), EMPTY)
        ),
        tap(() => {
          // Indicate we are loading
          loadingSubject.next(true);
        }),
        switchMap(([page, patientId]) =>
          // Switch into the observable for retrieving the next page
          this.getMessagePage(patientId, channel, page, offsetSubject.value)
        ),
        tap(() => {
          // Indicate we are done
          loadingSubject.next(false);
        })
      )
      .subscribe((messagePage) => {
        if (Object.keys(messagePage).length > 0) {
          // Assign the most recent message from the first page
          const latestUpdated = this.sortMessageData(messagePage.data)[0]?.updatedOn as string;

          // We only want it if it is more recent that the current date we are checking by.
          // This is so that between multiple channels we pick the best option on init.
          if (messagePage.page === 0 && Object.keys(messagePage.data).length > 0) {
            this.updateLastMessageReceivedAt(latestUpdated);
          }
          // We mix the existing data and the new data together (should take care of updates)
          const data = {
            ...messageDataSubject.value.data,
            ...messagePage.data,
          };
          messageDataSubject.next({
            data, // Used as source of truth
            dataArray: this.sortMessageData(data), // Used for rendering content in order
            count: messagePage.count,
          });
        }
      });
  }

  // Only updates if the new date is more recent
  private updateLastMessageReceivedAt(newDate: string) {
    if (DateTime.fromISO(newDate) > DateTime.fromISO(this.lastMessageReceivedAt)) {
      this.lastMessageReceivedAt = DateUtils.cleanTimestampString(newDate);
    }
  }
}
