// saving codes as const to implement type enforcement,
// referenced for enum below
/**
 * NOTE: all CPT codes must be unique. This array leverages const assertion for type enforcement in the DTO.
 * To add new CPT codes:
 * 1. Add numeric value to codes here
 * 2. Add code, key and label to /enums/cpt.ts
 * */
export type ActiveCptCodes =
  | 51798
  | 70030
  | 70100
  | 70110
  | 70140
  | 70150
  | 70160
  | 70190
  | 70200
  | 70210
  | 70220
  | 70250
  | 70260
  | 70328
  | 70330
  | 70360
  | 71015
  | 71022
  | 71045
  | 71046
  | 71047
  | 71048
  | 71100
  | 71101
  | 71110
  | 71111
  | 71120
  | 71130
  | 72010
  | 72020
  | 72040
  | 72050
  | 72052
  | 72069
  | 72070
  | 72072
  | 72074
  | 72080
  | 72090
  | 72100
  | 72110
  | 72114
  | 72120
  | 72170
  | 72190
  | 72200
  | 72202
  | 72220
  | 73000
  | 73010
  | 73020
  | 73030
  | 73050
  | 73060
  | 73070
  | 73080
  | 73090
  | 73092
  | 73100
  | 73110
  | 73120
  | 73130
  | 73140
  | 73500
  | 73501
  | 73502
  | 73503
  | 73510
  | 73520
  | 73521
  | 73522
  | 73523
  | 73540
  | 73550
  | 73551
  | 73552
  | 73560
  | 73562
  | 73564
  | 73565
  | 73590
  | 73592
  | 73600
  | 73610
  | 73620
  | 73630
  | 73650
  | 73660
  | 74018
  | 74019
  | 74021
  | 74022
  | 76536
  | 76604
  | 76700
  | 76705
  | 76770
  | 76775
  | 76881
  | 76882
  | 77072
  | 77074
  | 77075
  | 77076
  | 77077
  | 93010
  | 93306
  | 93922
  | 93923
  | 93925
  | 93926;

// saving codes as const to implement type enforcement,
// referenced for enum below
const CPT_CODES: Record<string, ActiveCptCodes> = {
  xrayEye: 70030,
  xrayMandibleLessThanFour: 70100,
  xrayMandibleMinOfFour: 70110,
  xrayFacialBones: 70140,
  xrayFacialBonesMinOfThree: 70150,
  xrayNasalBones: 70160,
  xrayOpticForamina: 70190,
  xrayOrbits: 70200,
  xraySinusesLessThanThree: 70210,
  xraySinusesMinOfThree: 70220,
  xraySkullLessThanFour: 70250,
  xraySkullMinOfFour: 70260,
  xrayTmjJtLimited: 70328,
  xrayTmjJtComplete: 70330,
  xrayNeck: 70360,
  xrayChestOne: 71045,
  xrayChestStereo: 71015,
  xrayChestTwo: 71046,
  xrayChestTwoApicalLordotic: 71047,
  xrayChestApOblique: 71022,
  xrayChestFourOrMore: 71048,
  xrayRibsUnilateral: 71100,
  xrayRibsUnilateralApChest: 71101,
  xrayRibsBilateral: 71110,
  xrayRibsBilateralChest: 71111,
  xraySternum: 71120,
  xraySternoclavicularJts: 71130,
  xraySpineEntireApLateral: 72010,
  xraySpineOne: 72020,
  xrayCSpine: 72040,
  xrayCSpineFour: 72050,
  xrayCSpineOblqFlexExt: 72052,
  xraySpineStandingScoliosis: 72069,
  xrayTSpineTwo: 72070,
  xrayTSpineThree: 72072,
  xrayTSpineFour: 72074,
  xraySpineThoracolumbTwo: 72080,
  xraySpineScoliosisSupineErect: 72090,
  xrayLSpineTwoOrThree: 72100,
  xrayLSpineFour: 72110,
  xrayLSpineApLatBending: 72114,
  xrayLSpineBendingFour: 72120,
  xrayPelvisOneOrTwo: 72170,
  xrayPelvisThree: 72190,
  xraySacroiliacJtsLessThanThree: 72200,
  xraySacroiliacJtsThreeOrMore: 72202,
  xraySacrumCoccyx: 72220,
  xrayClavicle: 73000,
  xrayScapula: 73010,
  xrayShoulderOne: 73020,
  xrayShoulderTwo: 73030,
  xrayAcromioclavicularJtsBilateral: 73050,
  xrayHumerusTwo: 73060,
  xrayElbowTwo: 73070,
  xrayElbowThree: 73080,
  xrayForearmTwo: 73090,
  xrayUpperExtremityInfantTwo: 73092,
  xrayWristTwo: 73100,
  xrayWristThree: 73110,
  xrayWristHandTwo: 73120,
  xrayWristHandThree: 73130,
  xrayFingersTwo: 73140,
  xrayHipUnilateralOne: 73500,
  xrayHipUnilateralOneWithPelvis: 73501,
  xrayHipUnilateralTwoThreeWithPelvis: 73502,
  xrayHipUnilateralFourOrMoreWithPelvis: 73503,
  xrayHipUnilateralTwo: 73510,
  xrayHipsBilateralPelvis: 73520,
  xrayHipsBilateralTwoWithPelvis: 73521,
  xrayHipsBilateralThreeOrFourWithPelvis: 73522,
  xrayPelvisHipsFiveOrMore: 73523,
  xrayPelvisHipsChildTwo: 73540,
  xrayFemurTwo: 73550,
  xrayFemurOne: 73551,
  xrayFemurTwoMinimum: 73552,
  xrayKneeOneOrTwo: 73560,
  xrayKneeThree: 73562,
  xrayKneeFourOrMore: 73564,
  xrayKneeBilateralApStanding: 73565,
  xrayTibFibTwo: 73590,
  xrayLowerExtremityInfantMinTwo: 73592,
  xrayAnkleTwo: 73600,
  xrayAnkleThree: 73610,
  xrayFootTwo: 73620,
  xrayFootThree: 73630,
  xrayCalcaneusTwo: 73650,
  xrayToesTwo: 73660,
  xrayAbdomenOne: 74018,
  xrayAbdomenTwo: 74019,
  xrayAbdomenThreeOrMore: 74021,
  xrayAbdomenSeriesOne: 74022,
  xrayBoneAge: 77072,
  xrayOsseousSurveyLtd: 77074,
  xrayOsseousSurveyComplete: 77075,
  xrayOsseousSurveyInfant: 77076,
  xrayJointSurveySingleVwJtsLessThanTwo: 77077,
  usBladderScan: 51798,
  usTissueNeckThyroid: 76536,
  usChestMediastinum: 76604,
  usAbdominal: 76700,
  usAbdominalLimitedSingleOrganFu: 76705,
  usRetroperitonealRenalAortaNodes: 76770,
  usRetroperitonealLtdRenalAortaNodes: 76775,
  usExtremityNonvascular: 76881,
  usExtremityNonvascularRealTimeImageDoc: 76882,
  ekgTwelveLeadWithInterpretation: 93010,
  usExtremityArterialAbiBilateralLtd: 93922,
  abiNoninvasiveExtremityArteriesMultiLvls: 93923,
  usBilateralLowerArterial: 93925,
  usUnilateralLowerArterial: 93926,
  echoTransthoracicTwoDMModeRecord: 93306,
};

export enum CptCodes {
  xrayEye = CPT_CODES.xrayEye,
  xrayMandibleLessThanFour = CPT_CODES.xrayMandibleLessThanFour,
  xrayMandibleMinOfFour = CPT_CODES.xrayMandibleMinOfFour,
  xrayFacialBones = CPT_CODES.xrayFacialBones,
  xrayFacialBonesMinOfThree = CPT_CODES.xrayFacialBonesMinOfThree,
  xrayNasalBones = CPT_CODES.xrayNasalBones,
  xrayOpticForamina = CPT_CODES.xrayOpticForamina,
  xrayOrbits = CPT_CODES.xrayOrbits,
  xraySinusesLessThanThree = CPT_CODES.xraySinusesLessThanThree,
  xraySinusesMinOfThree = CPT_CODES.xraySinusesMinOfThree,
  xraySkullLessThanFour = CPT_CODES.xraySkullLessThanFour,
  xraySkullMinOfFour = CPT_CODES.xraySkullMinOfFour,
  xrayTmjJtLimited = CPT_CODES.xrayTmjJtLimited,
  xrayTmjJtComplete = CPT_CODES.xrayTmjJtComplete,
  xrayNeck = CPT_CODES.xrayNeck,
  xrayChestOne = CPT_CODES.xrayChestOne,
  xrayChestStereo = CPT_CODES.xrayChestStereo,
  xrayChestTwo = CPT_CODES.xrayChestTwo,
  xrayChestTwoApicalLordotic = CPT_CODES.xrayChestTwoApicalLordotic,
  xrayChestApOblique = CPT_CODES.xrayChestApOblique,
  xrayChestFourOrMore = CPT_CODES.xrayChestFourOrMore,
  xrayRibsUnilateral = CPT_CODES.xrayRibsUnilateral,
  xrayRibsUnilateralApChest = CPT_CODES.xrayRibsUnilateralApChest,
  xrayRibsBilateral = CPT_CODES.xrayRibsBilateral,
  xrayRibsBilateralChest = CPT_CODES.xrayRibsBilateralChest,
  xraySternum = CPT_CODES.xraySternum,
  xraySternoclavicularJts = CPT_CODES.xraySternoclavicularJts,
  xraySpineEntireApLateral = CPT_CODES.xraySpineEntireApLateral,
  xraySpineOne = CPT_CODES.xraySpineOne,
  xrayCSpine = CPT_CODES.xrayCSpine,
  xrayCSpineFour = CPT_CODES.xrayCSpineFour,
  xrayCSpineOblqFlexExt = CPT_CODES.xrayCSpineOblqFlexExt,
  xraySpineStandingScoliosis = CPT_CODES.xraySpineStandingScoliosis,
  xrayTSpineTwo = CPT_CODES.xrayTSpineTwo,
  xrayTSpineThree = CPT_CODES.xrayTSpineThree,
  xrayTSpineFour = CPT_CODES.xrayTSpineFour,
  xraySpineThoracolumbTwo = CPT_CODES.xraySpineThoracolumbTwo,
  xraySpineScoliosisSupineErect = CPT_CODES.xraySpineScoliosisSupineErect,
  xrayLSpineTwoOrThree = CPT_CODES.xrayLSpineTwoOrThree,
  xrayLSpineFour = CPT_CODES.xrayLSpineFour,
  xrayLSpineApLatBending = CPT_CODES.xrayLSpineApLatBending,
  xrayLSpineBendingFour = CPT_CODES.xrayLSpineBendingFour,
  xrayPelvisOneOrTwo = CPT_CODES.xrayPelvisOneOrTwo,
  xrayPelvisThree = CPT_CODES.xrayPelvisThree,
  xraySacroiliacJtsLessThanThree = CPT_CODES.xraySacroiliacJtsLessThanThree,
  xraySacroiliacJtsThreeOrMore = CPT_CODES.xraySacroiliacJtsThreeOrMore,
  xraySacrumCoccyx = CPT_CODES.xraySacrumCoccyx,
  xrayClavicle = CPT_CODES.xrayClavicle,
  xrayScapula = CPT_CODES.xrayScapula,
  xrayShoulderOne = CPT_CODES.xrayShoulderOne,
  xrayShoulderTwo = CPT_CODES.xrayShoulderTwo,
  xrayAcromioclavicularJtsBilateral = CPT_CODES.xrayAcromioclavicularJtsBilateral,
  xrayHumerusTwo = CPT_CODES.xrayHumerusTwo,
  xrayElbowTwo = CPT_CODES.xrayElbowTwo,
  xrayElbowThree = CPT_CODES.xrayElbowThree,
  xrayForearmTwo = CPT_CODES.xrayForearmTwo,
  xrayUpperExtremityInfantTwo = CPT_CODES.xrayUpperExtremityInfantTwo,
  xrayWristTwo = CPT_CODES.xrayWristTwo,
  xrayWristThree = CPT_CODES.xrayWristThree,
  xrayWristHandTwo = CPT_CODES.xrayWristHandTwo,
  xrayWristHandThree = CPT_CODES.xrayWristHandThree,
  xrayFingersTwo = CPT_CODES.xrayFingersTwo,
  xrayHipUnilateralOne = CPT_CODES.xrayHipUnilateralOne,
  xrayHipUnilateralOneWithPelvis = CPT_CODES.xrayHipUnilateralOneWithPelvis,
  xrayHipUnilateralTwoThreeWithPelvis = CPT_CODES.xrayHipUnilateralTwoThreeWithPelvis,
  xrayHipUnilateralFourOrMoreWithPelvis = CPT_CODES.xrayHipUnilateralFourOrMoreWithPelvis,
  xrayHipUnilateralTwo = CPT_CODES.xrayHipUnilateralTwo,
  xrayHipsBilateralPelvis = CPT_CODES.xrayHipsBilateralPelvis,
  xrayHipsBilateralTwoWithPelvis = CPT_CODES.xrayHipsBilateralTwoWithPelvis,
  xrayHipsBilateralThreeOrFourWithPelvis = CPT_CODES.xrayHipsBilateralThreeOrFourWithPelvis,
  xrayPelvisHipsFiveOrMore = CPT_CODES.xrayPelvisHipsFiveOrMore,
  xrayPelvisHipsChildTwo = CPT_CODES.xrayPelvisHipsChildTwo,
  xrayFemurTwo = CPT_CODES.xrayFemurTwo,
  xrayFemurOne = CPT_CODES.xrayFemurOne,
  xrayFemurTwoMinimum = CPT_CODES.xrayFemurTwoMinimum,
  xrayKneeOneOrTwo = CPT_CODES.xrayKneeOneOrTwo,
  xrayKneeThree = CPT_CODES.xrayKneeThree,
  xrayKneeFourOrMore = CPT_CODES.xrayKneeFourOrMore,
  xrayKneeBilateralApStanding = CPT_CODES.xrayKneeBilateralApStanding,
  xrayTibFibTwo = CPT_CODES.xrayTibFibTwo,
  xrayLowerExtremityInfantMinTwo = CPT_CODES.xrayLowerExtremityInfantMinTwo,
  xrayAnkleTwo = CPT_CODES.xrayAnkleTwo,
  xrayAnkleThree = CPT_CODES.xrayAnkleThree,
  xrayFootTwo = CPT_CODES.xrayFootTwo,
  xrayFootThree = CPT_CODES.xrayFootThree,
  xrayCalcaneusTwo = CPT_CODES.xrayCalcaneusTwo,
  xrayToesTwo = CPT_CODES.xrayToesTwo,
  xrayAbdomenOne = CPT_CODES.xrayAbdomenOne,
  xrayAbdomenTwo = CPT_CODES.xrayAbdomenTwo,
  xrayAbdomenThreeOrMore = CPT_CODES.xrayAbdomenThreeOrMore,
  xrayAbdomenSeriesOne = CPT_CODES.xrayAbdomenSeriesOne,
  xrayBoneAge = CPT_CODES.xrayBoneAge,
  xrayOsseousSurveyLtd = CPT_CODES.xrayOsseousSurveyLtd,
  xrayOsseousSurveyComplete = CPT_CODES.xrayOsseousSurveyComplete,
  xrayOsseousSurveyInfant = CPT_CODES.xrayOsseousSurveyInfant,
  xrayJointSurveySingleVwJtsLessThanTwo = CPT_CODES.xrayJointSurveySingleVwJtsLessThanTwo,
  usBladderScan = CPT_CODES.usBladderScan,
  usTissueNeckThyroid = CPT_CODES.usTissueNeckThyroid,
  usChestMediastinum = CPT_CODES.usChestMediastinum,
  usAbdominal = CPT_CODES.usAbdominal,
  usAbdominalLimitedSingleOrganFu = CPT_CODES.usAbdominalLimitedSingleOrganFu,
  usRetroperitonealRenalAortaNodes = CPT_CODES.usRetroperitonealRenalAortaNodes,
  usRetroperitonealLtdRenalAortaNodes = CPT_CODES.usRetroperitonealLtdRenalAortaNodes,
  usExtremityNonvascular = CPT_CODES.usExtremityNonvascular,
  usExtremityNonvascularRealTimeImageDoc = CPT_CODES.usExtremityNonvascularRealTimeImageDoc,
  ekgTwelveLeadWithInterpretation = CPT_CODES.ekgTwelveLeadWithInterpretation,
  usExtremityArterialAbiBilateralLtd = CPT_CODES.usExtremityArterialAbiBilateralLtd,
  abiNoninvasiveExtremityArteriesMultiLvls = CPT_CODES.abiNoninvasiveExtremityArteriesMultiLvls,
  usBilateralLowerArterial = CPT_CODES.usBilateralLowerArterial,
  usUnilateralLowerArterial = CPT_CODES.usUnilateralLowerArterial,
  echoTransthoracicTwoDMModeRecord = CPT_CODES.echoTransthoracicTwoDMModeRecord,
}

export enum CptLabels {
  xrayEye = 'xray eye',
  xrayMandibleLessThanFour = 'xray mandible <4 views',
  xrayMandibleMinOfFour = 'xray mandible minimum of 4 views',
  xrayFacialBones = 'xray facial bones',
  xrayFacialBonesMinOfThree = 'xray facial bones minimum of 3 views',
  xrayNasalBones = 'xray nasal bones',
  xrayOpticForamina = 'xray optic foramina',
  xrayOrbits = 'xray orbits',
  xraySinusesLessThanThree = 'xray sinuses less than 3 views',
  xraySinusesMinOfThree = 'xray sinuses minimum of 3 views',
  xraySkullLessThanFour = 'xray skull less than 4 views',
  xraySkullMinOfFour = 'xray skull minimum of 4 views',
  xrayTmjJtLimited = 'xray TMJ jt limited',
  xrayTmjJtComplete = 'xray TMJ jt complete',
  xrayNeck = 'xray neck',
  xrayChestOne = 'xray chest 1 view',
  xrayChestStereo = 'xray chest stereo',
  xrayChestTwo = 'xray chest 2 views',
  xrayChestTwoApicalLordotic = 'xray chest 2 views & apical lordotic',
  xrayChestApOblique = 'xray chest AP & Oblique',
  xrayChestFourOrMore = 'xray chest 4 or more views',
  xrayRibsUnilateral = 'xray ribs unilateral',
  xrayRibsUnilateralApChest = 'xray ribs unilateral & AP chest',
  xrayRibsBilateral = 'xray ribs bilateral',
  xrayRibsBilateralChest = 'xray ribs bilateral & chest',
  xraySternum = 'xray sternum',
  xraySternoclavicularJts = 'xray sternoclavicular jt(s)',
  xraySpineEntireApLateral = 'xray spine entire w/AP & lateral',
  xraySpineOne = 'xray spine, 1 view',
  xrayCSpine = 'xray C spine',
  xrayCSpineFour = 'xray C spine 4 views',
  xrayCSpineOblqFlexExt = 'xray C spine oblq flex & ext',
  xraySpineStandingScoliosis = 'xray spine standing (scoliosis)',
  xrayTSpineTwo = 'xray T spine 2 views',
  xrayTSpineThree = 'xray T spine 3 views',
  xrayTSpineFour = 'xray T spine 4 views',
  xraySpineThoracolumbTwo = 'xray spine thoracolumb 2 views',
  xraySpineScoliosisSupineErect = 'xray spine scoliosis supine & erect',
  xrayLSpineTwoOrThree = 'xray L spine 2 or 3 views',
  xrayLSpineFour = 'xray L spine 4 views',
  xrayLSpineApLatBending = 'xray L spine Ap Lat & bending',
  xrayLSpineBendingFour = 'xray L spine-bending 4 views',
  xrayPelvisOneOrTwo = 'xray pelvis 1 or 2 views',
  xrayPelvisThree = 'xray pelvis 3 views',
  xraySacroiliacJtsLessThanThree = 'xray sacroiliac jts <3 views',
  xraySacroiliacJtsThreeOrMore = 'xray sacroiliac jts 3 or more views',
  xraySacrumCoccyx = 'xray sacrum & coccyx',
  xrayClavicle = 'xray clavicle',
  xrayScapula = 'xray scapula',
  xrayShoulderOne = 'xray shoulder 1 views',
  xrayShoulderTwo = 'xray shoulder 2 views',
  xrayAcromioclavicularJtsBilateral = 'xray acromioclavicular jts bilateral',
  xrayHumerusTwo = 'xray humerus 2 views',
  xrayElbowTwo = 'xray elbow 2 views',
  xrayElbowThree = 'xray elbow 3 views',
  xrayForearmTwo = 'xray forearm 2 views',
  xrayUpperExtremityInfantTwo = 'xray upper extremity infant 2 views',
  xrayWristTwo = 'xray wrist 2 views',
  xrayWristThree = 'xray wrist 3 views',
  xrayWristHandTwo = 'xray hand 2 views',
  xrayWristHandThree = 'xray hand 3 views',
  xrayFingersTwo = 'xray finger(s) 2 views',
  xrayHipUnilateralOne = 'xray hip unilateral 1 view',
  xrayHipUnilateralOneWithPelvis = 'xray hip unilateral 1 view w/pelvis',
  xrayHipUnilateralTwoThreeWithPelvis = 'xray hip unilateral 2-3 views w/pelvis',
  xrayHipUnilateralFourOrMoreWithPelvis = 'xray hip unilateral 4 or more views w/pelvis',
  xrayHipUnilateralTwo = 'xray hip unilateral 2 views',
  xrayHipsBilateralPelvis = 'xray hips bilateral & pelvis',
  xrayHipsBilateralTwoWithPelvis = 'xray hips bilateral 2 views w/pelvis',
  xrayHipsBilateralThreeOrFourWithPelvis = 'xray hips bilateral 3-4 views w/pelvis',
  xrayPelvisHipsFiveOrMore = 'xray pelvis & hips 4>views w/pelvis',
  xrayPelvisHipsChildTwo = 'xray pelvis & hips child 2 views',
  xrayFemurTwo = 'xray femur 2 views',
  xrayFemurOne = 'xray femur 1 view',
  xrayFemurTwoMinimum = 'xray femur 2 views minimum',
  xrayKneeOneOrTwo = 'xray knee 1 or 2 views',
  xrayKneeThree = 'xray knee 3 views',
  xrayKneeFourOrMore = 'xray knee 4 or more views',
  xrayKneeBilateralApStanding = 'xray knee bilateral Ap standing',
  xrayTibFibTwo = 'xray tib & fib 2 views',
  xrayLowerExtremityInfantMinTwo = 'xray lower extremity infant min 2 views',
  xrayAnkleTwo = 'xray ankle 2 views',
  xrayAnkleThree = 'xray ankle 3 views',
  xrayFootTwo = 'xray foot 2 views',
  xrayFootThree = 'xray foot 3 views',
  xrayCalcaneusTwo = 'xray calcaneus 2 views',
  xrayToesTwo = 'xray toe(s) 2 views',
  xrayAbdomenOne = 'xray abdomen 1 view',
  xrayAbdomenTwo = 'xray abdomen 2 views',
  xrayAbdomenThreeOrMore = 'xray abdomen 3 view or more',
  xrayAbdomenSeriesOne = 'xray abdomen series (supine, erect, and/or decubitus views, 1 view chest)',
  xrayBoneAge = 'xray bone age',
  xrayOsseousSurveyLtd = 'xray osseous survey ltd',
  xrayOsseousSurveyComplete = 'xray osseous survey complete',
  xrayOsseousSurveyInfant = 'xray osseous survey infant',
  xrayJointSurveySingleVwJtsLessThanTwo = 'xray joint survey-single vw <2 jts',
  usBladderScan = 'US bladder scan',
  usTissueNeckThyroid = 'US tissue neck, thyroid',
  usChestMediastinum = 'US chest, mediastinum',
  usAbdominal = 'US abdominal',
  usAbdominalLimitedSingleOrganFu = 'US abdominal limited single organ f/u',
  usRetroperitonealRenalAortaNodes = 'US retroperitoneal-renal aorta nodes',
  usRetroperitonealLtdRenalAortaNodes = 'US retroperitoneal ltd-renal aorta nodes',
  usExtremityNonvascular = 'US extremity (nonvascular)',
  usExtremityNonvascularRealTimeImageDoc = 'US extremity nonvascular real time with image doc',
  ekgTwelveLeadWithInterpretation = 'EKG 12 lead w/interpretation',
  usExtremityArterialAbiBilateralLtd = 'US extremity arterial ABI bilateral ltd',
  abiNoninvasiveExtremityArteriesMultiLvls = 'ABI noninvasive extremity arteries multi lvls',
  usBilateralLowerArterial = 'US bilateral lower arterial',
  usUnilateralLowerArterial = 'US unilateral lower arterial',
  echoTransthoracicTwoDMModeRecord = 'Echo Transthoracic 2d, m-mode record',
}
