<mat-sidenav-container [autosize]="true">
  <mat-sidenav
    color="accent"
    mode="side"
    opened
    [ngClass]="{ opened: (opened | async), closed: !(opened | async) }"
    *ngIf="patient$ | async as patient"
  >
    <div class="sidebar-content">
      <!-- Patient name/id -->
      <div class="nav-header bottom-border">
        <button
          (click)="navigationService.toggleSidenav()"
          mat-icon-button
          class="nav-button"
          [matTooltip]="(opened | async) ? 'Collapse sidebar' : 'Expand sidebar'"
          matTooltipPosition="right"
        >
          <mat-icon>{{ (opened | async) ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</mat-icon>
        </button>
        <div *ngIf="opened | async">
          <h2>
            {{ patient.name }}
          </h2>
          <label *ngIf="patient.preferredName"> Preferred name: {{ patient.preferredName }} </label>
          <h4>{{ patient.chronoChartId }} - {{ patient.id }}</h4>
          <h4>
            Employer:
            <div *ngIf="patient?.membership?.company; else unknownBlock">
              {{ patient.membership.company.name }}
            </div>
            <ng-template #unknownBlock>
              <span class="mat-text-warn"> [Unknown] </span>
            </ng-template>
          </h4>
          <h5 *ngIf="patient.deleted" class="mat-text-warn">(Deleted)</h5>
          <h5 *ngIf="!patient.deleted && !patient.active" class="mat-text-warn">(Deactivated)</h5>
          <div
            *ngIf="environment.featuresEnabled.elation"
            style="margin-top: 20px; margin-bottom: 15px"
            [reactComponent]="ElationChartButtonWrapper"
            [props]="{
              patient: patient,
              patientService: patientService
            }"
          ></div>
        </div>
      </div>
      <!-- Nav buttons -->
      <div fxLayout="{{ (opened | async) ? 'row' : 'column' }}" class="button--container bottom-border">
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', 'history', patient.id]"
          matTooltip="Medical History"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Medical History</span>
          <mat-icon>article</mat-icon>
        </button>
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', patient.id, 'charts']"
          matTooltip="Charts"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Charts</span>
          <mat-icon>inventory</mat-icon>
        </button>
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', 'vitals', patient.id]"
          matTooltip="Vitals"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Vitals</span>
          <mat-icon>assessment</mat-icon>
        </button>
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          (click)="navigateToDocumentsTable(patient.id)"
          matTooltip="Documents"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Documents</span>
          <mat-icon>content_copy</mat-icon>
        </button>
        <div class="break"></div>
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', 'schedule', patient.id]"
          matTooltip="Schedule"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Schedule</span>
          <mat-icon>event</mat-icon>
        </button>
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', 'chat', patient.id]"
          matTooltip="Chat"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <mat-icon
            matBadge="{{ unreadMessageCountFromPatient | async }}"
            matBadgeColor="{{ (unreadMessageCountFromPatient | async) > 0 ? 'warn' : 'accent' }}"
            matBadgeOverlap="true"
            >question_answer</mat-icon
          >
          <span class="cdk-visually-hidden">Chat ({{ unreadMessageCountFromPatient | async }})</span>
        </button>
        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', 'invoices', patient.id]"
          matTooltip="Invoices"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Invoices</span>
          <mat-icon>request_quote</mat-icon>
        </button>

        <button
          class="nav-button"
          mat-icon-button
          routerLinkActive="active"
          [routerLink]="['/', 'patients', 'view', patient.id]"
          matTooltip="Account Info"
          [matTooltipPosition]="(opened | async) ? 'above' : 'right'"
        >
          <span class="cdk-visually-hidden">Account Info</span>
          <mat-icon>account_circle</mat-icon>
        </button>
      </div>
      <!-- Patient info -->
      <mat-expansion-panel
        [expanded]="true"
        class="patient-info"
        [ngClass]="{ hidden: !(opened | async) }"
        *ngIf="opened | async"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Patient Info </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="opened | async" class="details-list">
          <div
            [reactComponent]="PatientInfoSummaryWrapper"
            [props]="{
              alertService: this.alertService,
              patient: patient,
              capsuleEligibilityCheckInProgress: this.capsuleEligibilityCheckInProgress,
              capsuleDeliveryAvailable: this.capsuleDeliveryAvailable
            }"
          ></div>
        </div>
      </mat-expansion-panel>
      <app-patient-info *ngIf="patient" [opened]="opened | async" [patient]="patient"></app-patient-info>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'sidenav-closed--content': !(opened | async) }" id="main-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
